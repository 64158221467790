import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isPlatformServer } from '@angular/common';

import { LegacySafeStorageService } from '@service/legacy/legacy-safe-storage.service';
import { LegacyRouteHelper } from '../../ui/legacy/helper/legacy-route-paths';

import { Observable } from 'rxjs';

export const legacyAuthGuard = (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | UrlTree | boolean => {
  const router = inject(Router);
  const service = inject(LegacySafeStorageService);
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId)) {
    return true;
  }
  if (!service.isLogin()) {
    service.setRedirectUrl(state.url);
    return router.navigate([LegacyRouteHelper.legacyLogin()], { queryParams: { redirect_uri: state.url } });
  }
  return true;
};

export const legacyLoginGuard = (): Observable<boolean> | Promise<boolean> | UrlTree | boolean => {
  const router = inject(Router);
  const service = inject(LegacySafeStorageService);
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId)) {
    return false;
  }
  if (service.isLogin()) {
    return router.navigate([LegacyRouteHelper.legacyHome()], { replaceUrl: true });
  }
  return !service.isLogin();
};
