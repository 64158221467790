import { makeEnvironmentProviders, NgModule } from '@angular/core';
import {
  RouterModule,
  ROUTES,
  Routes,
  TitleStrategy,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';

import { LegacyRouteHelper } from './ui/legacy/helper/legacy-route-paths';
import { RouteHelper, RoutePaths } from '@helper/route-paths';
import { LativTitle } from '@helper/lativ-title';
import { WindowDetectService } from '@service/window-detect.service';
import { Constants } from '@helper/constants';
import { StaticPages } from '@helper/static-pages';
import { authGuard, loginGuard, previewGuard } from '@service/auth-guard';
import { legacyAuthGuard, legacyLoginGuard } from '@service/legacy/legacy-auth-guard';

function routesFactory(service: WindowDetectService): Routes {
  if (service.isShortUrlWebsite()) {
    return [
      {
        path: RoutePaths.OPEN_APP,
        loadChildren: () => import('./ui/redirect-short-url/redirect-short-url.component').then(m => m.ROUTE),
      },
      {
        path: ':id',
        loadChildren: () => import('./ui/redirect-short-url/redirect-short-url.component').then(m => m.ROUTE),
      },
      {
        path: RoutePaths.ROOT,
        loadChildren: () => import('./ui/redirect-to-www/redirect-to-www.component').then(m => m.ROUTE),
      },
      { path: '**', redirectTo: RoutePaths.ROOT },
    ];
  }
  if (service.isLegacyWebsite()) {
    // legacy routes
    const legacyRoutes: Routes = [
      { path: '', redirectTo: LegacyRouteHelper.legacyHome(), pathMatch: 'full' },
      {
        path: LegacyRouteHelper.legacyLogin(),
        canActivate: [legacyLoginGuard],
        loadChildren: () =>
          service.isMobileDevice()
            ? import('./ui/legacy/login/mobile/legacy-login.component').then(m => m.ROUTE)
            : import('./ui/legacy/login/desktop/legacy-login.component').then(m => m.ROUTE),
      },
      // 忘記帳號
      {
        path: LegacyRouteHelper.legacyForgotAccount(),
        loadChildren: () => import('./ui/legacy/forgot-account/legacy-forgot-account.component').then(m => m.ROUTE),
      },
      // 忘記密碼
      {
        path: LegacyRouteHelper.legacyForgotPassword(),
        loadChildren: () =>
          service.isMobileDevice()
            ? import('./ui/legacy/forgot-password/mobile/legacy-forgot-password.component').then(m => m.ROUTE)
            : import('./ui/legacy/forgot-password/desktop/legacy-forgot-password.component').then(m => m.ROUTE),
      },
      // 重設密碼, 使用忘記密碼的 component, 若有參數就是重設密碼.
      {
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.RESET_PASSWORD}/:id/:serialNo`,
        loadChildren: () =>
          service.isMobileDevice()
            ? import('./ui/legacy/forgot-password/mobile/legacy-forgot-password.component').then(m => m.ROUTE)
            : import('./ui/legacy/forgot-password/desktop/legacy-forgot-password.component').then(m => m.ROUTE),
      },
      {
        path: LegacyRouteHelper.legacyHome(),
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/common/home/legacy-home.component').then(m => m.ROUTE),
      },
      // 七日無條件退貨 靜態頁面
      {
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.PAGE}/${StaticPages.freeReturns}`,
        loadChildren: () =>
          import('./ui/page/fees-refund-static-page/fees-refund-static-page.component').then(m => m.ROUTE),
      },
      {
        path: `${RoutePaths.PAGE}/${StaticPages.legacySystem}`,
        loadChildren: () =>
          import('./ui/page/legacy-system-static-page/legacy-system-static-page.component').then(m => m.ROUTE),
      },
      {
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.PAGE}/:id`,
        loadChildren: () => import('./ui/legacy/page/legacy-page.component').then(m => m.ROUTE),
      },
      {
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.SUPPORT_SERVICE}`,
        canActivate: service.isMobileDevice() ? [authGuard] : [],
        loadChildren: () =>
          service.isMobileDevice()
            ? import('./ui/support-service/mobile/support-service.component').then(m => m.ROUTE)
            : import('./ui/support-service/desktop/support-service.component').then(m => m.ROUTE),
      },
      {
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.SUPPORT_SERVICE}/:qaType`,
        canActivate: service.isMobileDevice() ? [authGuard] : [],
        loadChildren: () =>
          service.isMobileDevice()
            ? import('./ui/support-service/mobile/support-service.component').then(m => m.ROUTE)
            : import('./ui/support-service/desktop/support-service.component').then(m => m.ROUTE),
      },
    ];
    if (service.isMobileDevice()) {
      // only enable for mobile
      // 申請退貨/退貨詳情
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.APPLY}/:orderId`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/order/apply-return/mobile/legacy-apply-return.component').then(m => m.ROUTE),
      });
      // 修改退貨
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.APPLY}/:orderId/:returnId`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/order/apply-return/mobile/legacy-apply-return.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.FINISH}/:orderId`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/order/return-detail/mobile/apply-finish/legacy-apply-finish.component').then(
            m => m.ROUTE
          ),
      });
      // 訂單
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyOrders(),
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/order/list/mobile/legacy-order-list.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.ORDER_DETAIL}/:orderNo`,
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/order/detail/mobile/legacy-order-detail.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.LOGISTICS_DETAIL}/:orderNo`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/order/logistic-detail/mobile/legacy-logistic-detail.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.REFUND_DETAIL}/:orderNo`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/order/refund-detail/mobile/legacy-refund-detail.component').then(m => m.ROUTE),
      });
      // 退貨明細
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.RETURN_DETAIL}/:orderId`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/order/return-detail/mobile/legacy-return-detail.component').then(m => m.ROUTE),
      });
      // 發票明細
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.RECEIPT_DETAIL}/:orderNo`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/order/receipt-detail/mobile/legacy-receipt-detail.component').then(m => m.ROUTE),
      });
      // 評價
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/${RoutePaths.RATE_DETAIL}/:orderNo`,
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/order/rating/mobile/legacy-order-rating.component').then(m => m.ROUTE),
      });
      // 退款結清
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyRefundAccount(),
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/refund/refund-account/mobile/legacy-refund-account.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: LegacyRouteHelper.legacySettlementRecord(),
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/refund/settlement-record/mobile/legacy-settlement-record.component').then(m => m.ROUTE),
      });
      // 管理/編輯 - 銀行帳戶
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyBankAccountList(),
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/refund/account-list/mobile/legacy-account-list.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyCreateRefundAccount(),
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/refund/account-list/mobile/account-edit/legacy-account-edit.component').then(
            m => m.ROUTE
          ),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.USER}/${RoutePaths.ACCOUNT_EDIT}/:accountId`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/refund/account-list/mobile/account-edit/legacy-account-edit.component').then(
            m => m.ROUTE
          ),
      });
      // 選擇/編輯 - 銀行帳戶
      legacyRoutes.push({
        path: LegacyRouteHelper.legacySelectBankAccount(),
        canActivate: [legacyAuthGuard],
        data: { select: true },
        loadChildren: () =>
          import('./ui/legacy/refund/account-list/mobile/legacy-account-list.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyCreateApplyAccount(),
        canActivate: [legacyAuthGuard],
        data: { apply: true },
        loadChildren: () =>
          import('./ui/legacy/refund/account-list/mobile/account-edit/legacy-account-edit.component').then(
            m => m.ROUTE
          ),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.USER}/${RoutePaths.SELECT_EDIT_ACCOUNT}/:accountId`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/refund/account-list/mobile/account-edit/legacy-account-edit.component').then(
            m => m.ROUTE
          ),
      });
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyApplySettlement(),
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/refund/apply-settlement/mobile/legacy-apply-settlement.component').then(m => m.ROUTE),
      });
      // 消息中心，僅呈現 交易訊息 及 客服中心 所以加上 legacyAuthGuard
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyNotification(),
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/notification/mobile/legacy-notification.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacyNotification()}/:type`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/notification/mobile/notification-list/legacy-notification-list.component').then(
            m => m.ROUTE
          ),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.USER}/${LegacyRouteHelper.question}/:orderNo`,
        canActivate: [legacyAuthGuard],
        loadChildren: () =>
          import('./ui/legacy/notification/mobile/question-list/legacy-question-list.component').then(m => m.ROUTE),
      });
      // 線上客服
      legacyRoutes.push({
        path: LegacyRouteHelper.legacyCustomerService(),
        canActivate: [authGuard],
        loadChildren: () => import('./ui/customer-service/mobile/customer-service.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.CUSTOMER_SERVICE}/:orderNo`,
        canActivate: [authGuard],
        loadChildren: () => import('./ui/customer-service/mobile/customer-service.component').then(m => m.ROUTE),
      });
    } else {
      legacyRoutes.push({
        // mobile 只有 home 沒有 member 頁面
        path: LegacyRouteHelper.legacyUser(),
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/user/desktop/legacy-user.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.USER}/:page`,
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/user/desktop/legacy-user.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.USER}/:page/:state`,
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/user/desktop/legacy-user.component').then(m => m.ROUTE),
      });
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/:page/:orderNo`,
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/user/desktop/legacy-user.component').then(m => m.ROUTE),
      });
      // 申請退貨
      legacyRoutes.push({
        path: `${LegacyRouteHelper.legacy}/${RoutePaths.ORDERS}/:page/:orderNo/:returnId`,
        canActivate: [legacyAuthGuard],
        loadChildren: () => import('./ui/legacy/user/desktop/legacy-user.component').then(m => m.ROUTE),
      });
    }
    legacyRoutes.push({ path: '**', redirectTo: LegacyRouteHelper.legacyHome() });
    return legacyRoutes;
  }
  if (service.isWebsiteForApp()) {
    return service.isMobileDevice()
      ? [
          {
            path: RoutePaths.ROOT,
            loadChildren: () => import('./ui/redirect-to-www/redirect-to-www.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAGE}/${StaticPages.freeReturns}`,
            loadChildren: () =>
              import('./ui/page/fees-refund-static-page/fees-refund-static-page.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAGE}/${StaticPages.legacySystem}`,
            loadChildren: () =>
              import('./ui/page/legacy-system-static-page/legacy-system-static-page.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAGE_UPPERCASE}/${StaticPages.freeReturns}`,
            loadChildren: () =>
              import('./ui/page/fees-refund-static-page/fees-refund-static-page.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAGE_UPPERCASE}/${StaticPages.legacySystem}`,
            loadChildren: () =>
              import('./ui/page/legacy-system-static-page/legacy-system-static-page.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAGE}/:id`,
            loadChildren: () => import('./ui/page/page.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAGE_UPPERCASE}/:id`,
            loadChildren: () => import('./ui/page/page.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAYMENT}/:orderNo`,
            loadChildren: () => import('./ui/payment/payment.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.PAYMENT_RESULT}/:orderNo`,
            loadChildren: () => import('./ui/payment/payment.component').then(m => m.ROUTE),
          },
          {
            path: `${RoutePaths.CREDIT_CARD}/${RoutePaths.PAYMENT}/:orderNo`,
            loadChildren: () => import('./ui/credit-card-payment/credit-card-payment.component').then(m => m.ROUTE),
          },
          {
            path: RoutePaths.UPGRADE,
            loadChildren: () => import('./ui/upgrade/upgrade.component').then(m => m.ROUTE),
          },
          { path: '**', redirectTo: RoutePaths.ROOT },
        ]
      : [
          {
            path: RoutePaths.ROOT,
            loadChildren: () => import('./ui/redirect-to-www/redirect-to-www.component').then(m => m.ROUTE),
          },
          { path: '**', redirectTo: RoutePaths.ROOT },
        ];
  }
  // normal routes
  const routes: Routes = [
    { path: '', redirectTo: RoutePaths.ROOT, pathMatch: 'full' },
    {
      path: RoutePaths.ROOT,
      title: Constants.PAGE_TITLE,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/home/mobile/home.component').then(m => m.ROUTE)
          : import('./ui/home/desktop/home.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PREVIEW}/${RoutePaths.HOME}/:waterfallId`,
      canActivate: [previewGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/home/mobile/home.component').then(m => m.ROUTE)
          : import('./ui/home/desktop/home.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.HOME}/shareapp`,
      loadChildren: () => import('./ui/page/page.component').then(m => m.ROUTE),
    },
    {
      path: `Home/ResetPW`, // 特別備註：email 重設密碼, store 導頁路由.
      loadChildren: () => import('./ui/redirect-to-legacy/redirect-to-legacy.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.HOME}/:nameEn`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/home/mobile/home.component').then(m => m.ROUTE)
          : import('./ui/home/desktop/home.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PREVIEW}/${RoutePaths.HOME}/:nameEn`,
      canActivate: [previewGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/home/mobile/home.component').then(m => m.ROUTE)
          : import('./ui/home/desktop/home.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.LOGIN,
      canActivate: [loginGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/login/mobile/login.component').then(m => m.ROUTE)
          : import('./ui/login/desktop/login.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.USER,
      canActivate: [authGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/user/mobile/user.component').then(m => m.ROUTE)
          : import('./ui/user/desktop/user.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.SALES_EVENT,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/sales-event/mobile/sales-event.component').then(m => m.ROUTE)
          : import('./ui/sales-event/desktop/sales-event.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SALES_EVENT}/:eventId`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/sales-event/mobile/sales-event.component').then(m => m.ROUTE)
          : import('./ui/sales-event/desktop/sales-event.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SALES_EVENT}/:eventId/:nameEn`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/sales-event/mobile/sales-event.component').then(m => m.ROUTE)
          : import('./ui/sales-event/desktop/sales-event.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.CART,
      canActivate: [authGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/cart/mobile/cart.component').then(m => m.ROUTE)
          : import('./ui/cart/desktop/cart.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.COMMENTS,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/rating/mobile/rating.component').then(m => m.ROUTE)
          : import('./ui/rating/desktop/rating.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.CART}/${RoutePaths.SHIPPING}`,
      canActivate: [authGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/cart/shipping/mobile/shipping.component').then(m => m.ROUTE)
          : import('./ui/cart/shipping/desktop/shipping.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.CART}/${RoutePaths.PAYMENT}`,
      canActivate: [authGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/cart/payment/mobile/payment.component').then(m => m.ROUTE)
          : import('./ui/cart/payment/desktop/payment.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.CART}/${RoutePaths.PAYMENT}/:orderId`,
      canActivate: [authGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/cart/payment/mobile/payment-result/payment-result.component').then(m => m.ROUTE)
          : import('./ui/cart/payment/desktop/payment-result/payment-result.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.CATEGORY}/:bigNameEn/:mediumNameEn/:smallNameEn`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/category-products/mobile/category-detail/category-detail-component').then(m => m.ROUTE)
          : import('./ui/category-products/desktop/category-products.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SPECIAL}/:pageNo`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/special/mobile/special.component').then(m => m.ROUTE)
          : import('./ui/special/desktop/special.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PREVIEW}/${RoutePaths.SPECIAL}/:pageNo`,
      canActivate: [previewGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/special/mobile/special.component').then(m => m.ROUTE)
          : import('./ui/special/desktop/special.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.STAGGERED}/:id`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/staggered-shop-window/mobile/staggered-shop-window.component').then(m => m.ROUTE)
          : import('./ui/staggered-shop-window/desktop/staggered-shop-window.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SHOP_WINDOW}/:shopWindowId`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/search/mobile/search.component').then(m => m.ROUTE)
          : import('./ui/shopwindow/desktop/shopwindow.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SHOP_WINDOW}/:shopWindowId/:bigNameEn`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/search/mobile/search.component').then(m => m.ROUTE)
          : import('./ui/shopwindow/desktop/shopwindow.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SEARCH}/:keyword`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/search/mobile/search.component').then(m => m.ROUTE)
          : import('./ui/search/desktop/search.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SEARCH}/:keyword/:bigNameEn`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/search/mobile/search.component').then(m => m.ROUTE)
          : import('./ui/search/desktop/search.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PRODUCT}/:skuId`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/product/mobile/product.component').then(m => m.ROUTE)
          : import('./ui/product/desktop/product.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PREVIEW}/${RoutePaths.PRODUCT}/:skuId`,
      canActivate: [previewGuard],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/product/mobile/product.component').then(m => m.ROUTE)
          : import('./ui/product/desktop/product.component').then(m => m.ROUTE),
    },
    // 七日無條件退貨 靜態頁面
    {
      path: `${RoutePaths.PAGE}/${StaticPages.freeReturns}`,
      loadChildren: () =>
        import('./ui/page/fees-refund-static-page/fees-refund-static-page.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PAGE}/${StaticPages.legacySystem}`,
      loadChildren: () =>
        import('./ui/page/legacy-system-static-page/legacy-system-static-page.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PAGE_UPPERCASE}/${StaticPages.freeReturns}`,
      loadChildren: () =>
        import('./ui/page/fees-refund-static-page/fees-refund-static-page.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PAGE_UPPERCASE}/${StaticPages.legacySystem}`,
      loadChildren: () =>
        import('./ui/page/legacy-system-static-page/legacy-system-static-page.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PAGE}/:id`,
      loadChildren: () => import('./ui/page/page.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PAGE_UPPERCASE}/:id`,
      loadChildren: () => import('./ui/page/page.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.SUPPORT_SERVICE,
      canActivate: service.isMobileDevice() ? [authGuard] : [],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/support-service/mobile/support-service.component').then(m => m.ROUTE)
          : import('./ui/support-service/desktop/support-service.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.SUPPORT_SERVICE}/:qaType`,
      canActivate: service.isMobileDevice() ? [authGuard] : [],
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/support-service/mobile/support-service.component').then(m => m.ROUTE)
          : import('./ui/support-service/desktop/support-service.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.OUTFIT,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/outfit/mobile/outfit.component').then(m => m.ROUTE)
          : import('./ui/outfit/desktop/outfit.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.OUTFIT}/:id`,
      loadChildren: () =>
        service.isMobileDevice()
          ? import('./ui/outfit/mobile/detail/outfit-detail.component').then(m => m.ROUTE)
          : import('./ui/outfit/desktop/detail/outfit-detail.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PAYMENT}/:orderNo`,
      loadChildren: () => import('./ui/payment/payment.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.PAYMENT_RESULT}/:orderNo`,
      loadChildren: () => import('./ui/payment/payment.component').then(m => m.ROUTE),
    },
    {
      path: `${RoutePaths.CREDIT_CARD}/${RoutePaths.PAYMENT}/:orderNo`,
      loadChildren: () => import('./ui/credit-card-payment/credit-card-payment.component').then(m => m.ROUTE),
    },
    {
      path: RoutePaths.UPGRADE,
      loadChildren: () => import('./ui/upgrade/upgrade.component').then(m => m.ROUTE),
    },
  ];
  if (service.isMobileDevice()) {
    // only enable for mobile
    routes.push({
      path: RoutePaths.CATEGORY,
      loadChildren: () => import('./ui/category-products/mobile/category-products.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.CATEGORY}/:bigNameEn`,
      loadChildren: () => import('./ui/category-products/mobile/category-products.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.OUTFIT}/:id/:index`,
      loadChildren: () => import('./ui/outfit/mobile/swiper/outfit-swiper.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.CATEGORY}/:bigNameEn/:mediumNameEn`,
      loadChildren: () => import('./ui/category-products/mobile/category-products.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.SPECIAL}/:pageNo/:tabNo`,
      loadChildren: () => import('./ui/special/mobile/special.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.HOME}/${RoutePaths.SALES_EVENT}`,
      loadChildren: () => import('./ui/home/mobile/home.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.HOME}/${RoutePaths.SALES_EVENT}/:eventId`,
      loadChildren: () => import('./ui/home/mobile/home.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.HOME}/${RoutePaths.SALES_EVENT}/:eventId/:nameEn`,
      loadChildren: () => import('./ui/home/mobile/home.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.SEARCH}`,
      loadChildren: () => import('./ui/search/mobile/search.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.promotion(),
      loadChildren: () => import('./ui/notification/mobile/promotion/promotion.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.notificationLogistics(),
      loadChildren: () => import('./ui/notification/mobile/record-info/record-info.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.notificationProduct(),
      loadChildren: () => import('./ui/notification/mobile/record-info/record-info.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.csRecord(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/notification/mobile/cs-record/cs-record.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.reminder(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/favorite/mobile/favorite.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ADDRESS}/:originPath/:addressType/:storeTypeOrCountryName`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/address/mobile/address-edit/address-edit.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ADDRESS}`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/address/mobile/address.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ADDRESS}/:originPath/:addressType`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/address/mobile/address-edit/address-edit.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ADDRESS}/:originPath/:addressType/:storeTypeOrCountryName/:addressId`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/address/mobile/address-edit/address-edit.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.CART}/${RoutePaths.RECIPIENT}`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/cart/recipient/mobile/recipient.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.CART}/${RoutePaths.CHOOSE_RECEIPT}`,
      loadChildren: () => import('./ui/cart/payment/mobile/choose-receipt/choose-receipt.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.GALLERY}/:productId/:colorId`,
      loadChildren: () => import('./ui/common/sku-gallery/sku-gallery.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.QA_CONTENT}/:qaName`,
      canActivate: [authGuard],
      loadChildren: () =>
        import('./ui/support-service/mobile/question-content/question-content.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.APP_QA_CONTENT}/:qaName`,
      loadChildren: () =>
        import('./ui/support-service/mobile/question-content/question-content.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.CUSTOMER_SERVICE}`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/customer-service/mobile/customer-service.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.CUSTOMER_SERVICE}/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/customer-service/mobile/customer-service.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ONLINE_CS}/:sessionId`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/customer-service/mobile/customer-service.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.notification(),
      loadChildren: () => import('./ui/notification/mobile/notification.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.favorite(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/favorite/mobile/favorite.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.profile(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/profile/mobile/profile.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.editPhone(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/profile/edit-phone/edit-phone.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.bindPhone(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/profile/bind-phone/bind-phone.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.bindInvoice(),
      loadChildren: () => import('./ui/profile/bind-invoice/bind-invoice.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.settings(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/setting/mobile/setting.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.APPLY}/:orderId`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/apply-return/mobile/apply-return.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.APPLY}/:orderId/:returnId`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/apply-return/mobile/apply-return.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.FINISH}/:orderId`,
      canActivate: [authGuard],
      loadChildren: () =>
        import('./ui/order/return-detail/mobile/apply-finish/apply-finish.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.RETURN_DETAIL}/:orderId`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/return-detail/mobile/return-detail.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.USER}/${RoutePaths.REFUND_ACCOUNT}`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/refund/refund-account/mobile/refund-account.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.bankAccountList(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/refund/account-list/mobile/account-list.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.selectBankAccount(),
      canActivate: [authGuard],
      data: { select: true },
      loadChildren: () => import('./ui/refund/account-list/mobile/account-list.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.createRefundAccount(),
      canActivate: [authGuard],
      loadChildren: () =>
        import('./ui/refund/account-list/mobile/account-edit/account-edit.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.createApplyAccount(),
      canActivate: [authGuard],
      data: { apply: true },
      loadChildren: () =>
        import('./ui/refund/account-list/mobile/account-edit/account-edit.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.settlementRecord(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/refund/settlement-record/mobile/settlement-record.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.USER}/${RoutePaths.ACCOUNT_EDIT}/:accountId`,
      canActivate: [authGuard],
      loadChildren: () =>
        import('./ui/refund/account-list/mobile/account-edit/account-edit.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.USER}/${RoutePaths.SELECT_EDIT_ACCOUNT}/:accountId`,
      canActivate: [authGuard],
      loadChildren: () =>
        import('./ui/refund/account-list/mobile/account-edit/account-edit.component').then(m => m.ROUTE),
    });
    routes.push({
      path: RouteHelper.applySettlement(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/refund/apply-settlement/mobile/apply-settlement.component').then(m => m.ROUTE),
    });
    // order related page in mobile
    routes.push({
      path: RouteHelper.orders(),
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/list/mobile/order-list.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RouteHelper.orders()}/:state`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/list/mobile/order-list.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.ORDER_DETAIL}/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/detail/mobile/order-detail.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.LOGISTICS_DETAIL}/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/logistic-detail/mobile/logistic-detail.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.REFUND_DETAIL}/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/refund-detail/mobile/refund-detail.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.RECEIPT_DETAIL}/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/receipt-detail/mobile/receipt-detail.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.CUSTOMER_SERVICE}/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/detail/mobile/order-detail.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/${RoutePaths.RATE_DETAIL}/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/order/rating/mobile/order-rating.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.COMMENTS}/:productId`,
      loadChildren: () => import('./ui/rating/mobile/rating.component').then(m => m.ROUTE),
    });
  } else {
    routes.push({
      // notification, favorites, orders, profile in desktop is user page partial view. specify by page.
      path: `${RoutePaths.USER}/:page`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/user/desktop/user.component').then(m => m.ROUTE),
    });
    routes.push({
      // according to mobile `${RouteHelper.orders()}/:state`
      path: `${RoutePaths.USER}/:page/:state`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/user/desktop/user.component').then(m => m.ROUTE),
    });
    routes.push({
      path: `${RoutePaths.ORDERS}/:page/:orderNo/:returnId`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/user/desktop/user.component').then(m => m.ROUTE),
    });
    routes.push({
      // order related page in desktop is user page partial view. specify by page.
      // detail: `${RoutePaths.ORDERS}/${RoutePaths.ORDER_DETAIL}/:orderNo`
      // detail: `${RoutePaths.ORDERS}/${RoutePaths.RATE_DETAIL}/:orderNo`
      path: `${RoutePaths.ORDERS}/:page/:orderNo`,
      canActivate: [authGuard],
      loadChildren: () => import('./ui/user/desktop/user.component').then(m => m.ROUTE),
    });
  }
  routes.push({ path: '**', redirectTo: RoutePaths.ROOT });
  return routes;
}

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
  providers: [
    makeEnvironmentProviders([
      {
        provide: ROUTES,
        useFactory: routesFactory,
        multi: true,
        deps: [WindowDetectService],
      },
      [
        withComponentInputBinding(),
        withInMemoryScrolling({
          scrollPositionRestoration: 'top',
          anchorScrolling: 'disabled',
        }),
      ],
    ]),
    {
      provide: TitleStrategy,
      useClass: LativTitle,
    },
  ],
})
export class AppRoutingModule {}
