import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LativLocationService extends Location {
  readonly locationReplaced = new Subject<string>();

  override replaceState(path: string, query?: string, state?: any): void {
    super.replaceState(path, query, state);
    this.locationReplaced.next(path.startsWith('/') ? path : `/${path}`);
  }
}
