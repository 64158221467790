import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

import { WindowDetectService } from '@service/window-detect.service';
import { Links } from '@helper/links';

@Injectable({
  providedIn: 'root',
})
export class LativTitle extends TitleStrategy {
  constructor(private readonly windowDetectService: WindowDetectService) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    this.windowDetectService.currentPath.set(Links.appendLocale(snapshot.url));
  }
}
