'use strict';

var isObject = require('../helpers/isObject');

// https://262.ecma-international.org/5.1/#sec-8

module.exports = function Type(x) {
  if (x === null) {
    return 'Null';
  }
  if (typeof x === 'undefined') {
    return 'Undefined';
  }
  if (isObject(x)) {
    return 'Object';
  }
  if (typeof x === 'number') {
    return 'Number';
  }
  if (typeof x === 'boolean') {
    return 'Boolean';
  }
  if (typeof x === 'string') {
    return 'String';
  }
};