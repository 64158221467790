/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import matchAll from 'string.prototype.matchall';

// polyfill for older browser
// https://app.bugsnag.com/mego-international-promotion-co-dot-ltd/store-frontend/errors/67d93daf4a63f0076609b60f?event_id=67d93dc30113084de6b40000&i=sk&m=oc
// https://app.bugsnag.com/mego-international-promotion-co-dot-ltd/store-frontend/errors/67d9830756e2cb408ae9a50a
matchAll.shim();
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (
    search: string | RegExp,
    replacement: string | ((substring: string, ...args: any[]) => string)
  ): string {
    if (typeof search === 'string') {
      return this.split(search).join(replacement as string);
    }
    return this.replace(new RegExp(search, 'g'), replacement as string);
  };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
