'use strict';

// var modulo = require('./modulo');
var $floor = require('math-intrinsics/floor');

// http://262.ecma-international.org/11.0/#eqn-floor

module.exports = function floor(x) {
  // return x - modulo(x, 1);
  if (typeof x === 'bigint') {
    return x;
  }
  return $floor(x);
};