<div class="app-container"
     [style.width]="appWidth()"
     [style.left.px]="-scrollLeft()"
     [class.overflow-app-container]="desktopOverflow()">
  <router-outlet></router-outlet>
</div>
@if (showSnow()) {
  <div id="snow" class="snow"></div>
}
@if (desktopOverflow() && !windowDetectService.isMobileDevice()) {
  <div class="x-scrollbar">
    <ng-scrollbar class="scrollbar"
                  [orientation]="'horizontal'"
                  [style.height.px]="10">
      <div class="overflow-app-container" [style.width]="appWidth()"></div>
    </ng-scrollbar>
  </div>
}
