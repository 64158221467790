'use strict';

var implementation = require('./implementation');
module.exports = function getPolyfill() {
  if (String.prototype.matchAll) {
    try {
      ''.matchAll(RegExp.prototype);
    } catch (e) {
      return String.prototype.matchAll;
    }
  }
  return implementation;
};