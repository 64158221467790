'use strict';

var GetIntrinsic = require('get-intrinsic');
var $ObjectCreate = GetIntrinsic('%Object.create%', true);
var $TypeError = require('es-errors/type');
var $SyntaxError = require('es-errors/syntax');
var IsArray = require('./IsArray');
var forEach = require('../helpers/forEach');
var isObject = require('../helpers/isObject');
var SLOT = require('internal-slot');
var hasProto = require('has-proto')();

// https://262.ecma-international.org/11.0/#sec-objectcreate

module.exports = function OrdinaryObjectCreate(proto) {
  if (proto !== null && !isObject(proto)) {
    throw new $TypeError('Assertion failed: `proto` must be null or an object');
  }
  var additionalInternalSlotsList = arguments.length < 2 ? [] : arguments[1];
  if (!IsArray(additionalInternalSlotsList)) {
    throw new $TypeError('Assertion failed: `additionalInternalSlotsList` must be an Array');
  }

  // var internalSlotsList = ['[[Prototype]]', '[[Extensible]]']; // step 1
  // internalSlotsList.push(...additionalInternalSlotsList); // step 2
  // var O = MakeBasicObject(internalSlotsList); // step 3
  // setProto(O, proto); // step 4
  // return O; // step 5

  var O;
  if ($ObjectCreate) {
    O = $ObjectCreate(proto);
  } else if (hasProto) {
    O = {
      __proto__: proto
    };
  } else {
    if (proto === null) {
      throw new $SyntaxError('native Object.create support is required to create null objects');
    }
    var T = function T() {};
    T.prototype = proto;
    O = new T();
  }
  if (additionalInternalSlotsList.length > 0) {
    forEach(additionalInternalSlotsList, function (slot) {
      SLOT.set(O, slot, void undefined);
    });
  }
  return O;
};