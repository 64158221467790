import { ErrorHandler, NgModule } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../environments/environment';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

export const bugsnagClient = Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  enabledReleaseStages: ['production', 'canary'],
  releaseStage: environment.releaseStage,
  autoTrackSessions: false,
  appVersion: environment.appVersion,
});

export class BugHandler extends BugsnagErrorHandler {
  readonly chunkLoadError = 'ChunkLoadError';
  readonly javaError = 'Java exception was raised during method invocation ';
  readonly firebaseOfflineError = 'installations/app-offline';
  readonly firebaseNetworkError = 'auth/network-request-failed';

  override handleError(error: any): void {
    if (typeof error?.message === 'string') {
      if (
        error.message.includes(this.javaError) ||
        error.message.includes(this.firebaseOfflineError) ||
        error.message.includes(this.firebaseNetworkError)
      ) {
        return;
      }
    }
    if (error instanceof HttpErrorResponse && error.status === 0) {
      // ignore network error
      return;
    }
    super.handleError(error);
    if (typeof error.message === 'string' && error.message.includes(this.chunkLoadError)) {
      try {
        window.location.reload();
      } catch {
        // ignore error
      }
    }
  }
}

export function errorHandlerFactory(): BugsnagErrorHandler {
  return new BugHandler(bugsnagClient);
}

@NgModule({
  providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }],
})
export class BugsangModule {}
