import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { LegacyHttpClient } from '@service/legacy/legacy-http-client';
import { LegacySafeStorageService } from '@service/legacy/legacy-safe-storage.service';
import { LegacyRouteHelper } from '../../ui/legacy/helper/legacy-route-paths';

import { catchError, EMPTY, Observable, throwError, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LegacyHttpInterceptor implements HttpInterceptor {
  private readonly timeout = 25 * 1000;
  private readonly authHeader = 'AUTHORIZATION';

  constructor(
    private legacyHttp: LegacyHttpClient,
    private router: Router,
    private legacySafeStorageService: LegacySafeStorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(environment.legacyApiURL)) {
      // 如果不是 legacy 的 API URL 就跳過.
      return next.handle(req);
    }
    return next.handle(req).pipe(
      timeout(this.timeout),
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          if (error.url === this.legacyHttp.refreshUrl) {
            this.legacySafeStorageService.clearLegacyToken();
            this.router.navigate([LegacyRouteHelper.legacyLogin()], { replaceUrl: true });
            return EMPTY;
          } else {
            this.legacyHttp.refreshToken().subscribe(token => {
              const newReq = req.clone({ headers: req.headers.set(this.authHeader, token.accessToken) });
              return next.handle(newReq);
            });
          }
        }
        return throwError(() => error);
      })
    );
  }
}
