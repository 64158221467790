import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  Title,
  withNoHttpTransferCache,
  withEventReplay,
} from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DecimalPipe, Location } from '@angular/common';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { UrlSerializer } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LativTitleService } from '@service/lativ-title.service';
import { LativAPIInterceptor } from '@service/lativ.interceptor';
import { LegacyHttpInterceptor } from '@service/legacy/legacy-http-interceptor';
import { BugsangModule } from './bugsnag.module';
import { LativLocationService } from '@service/lativ-location.service';
import { environment } from '../environments/environment';
import { ParenthesesUrlSerializer } from '@helper/parentheses-url-serializer';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import Bugsnag from '@bugsnag/js';
import { NgScrollbar, provideScrollbarPolyfill } from 'ngx-scrollbar';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BugsangModule,
    MatDialogModule,
    MatBottomSheetModule,
    NgScrollbar,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LativAPIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LegacyHttpInterceptor,
      multi: true,
    },
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideClientHydration(withNoHttpTransferCache(), withEventReplay()),
    DecimalPipe,
    {
      provide: Title,
      useClass: LativTitleService,
    },
    {
      provide: Location,
      useClass: LativLocationService,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { scrollStrategy: new NoopScrollStrategy() },
    },
    {
      provide: UrlSerializer,
      useClass: ParenthesesUrlSerializer,
    },
    provideScrollbarPolyfill('assets/scroll-timeline-polyfill.js'),
  ],
})
export class AppModule {
  constructor() {
    // invoke login state
    const app = initializeApp(environment.firebase);
    const user = getAuth(app).currentUser;
    if (user) {
      Bugsnag.setUser(user.uid);
    }
  }
}
