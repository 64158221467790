import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ParenthesesUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    return super.parse(this.removeParentheses(url));
  }

  private removeParentheses(url: string): string {
    const regex = /([()])/g;
    return url.replace(regex, match => {
      if (match === '(') {
        return '%28'; // URL-encoded version of "("
      } else {
        return '%29'; // URL-encoded version of ")"
      }
    });
  }
}
