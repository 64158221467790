import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

import { Constants } from '@helper/constants';

@Injectable({
  providedIn: 'root',
})
export class LativTitleService extends Title {
  override setTitle(newTitle: string): void {
    if (newTitle === Constants.PAGE_TITLE) {
      super.setTitle(newTitle);
    } else {
      const title = newTitle ? `${newTitle} - ${Constants.PAGE_TITLE}` : Constants.PAGE_TITLE;
      super.setTitle(title);
    }
  }
}
