'use strict';

var GetIntrinsic = require('get-intrinsic');
var $defineProperty = GetIntrinsic('%Object.defineProperty%', true);
var hasToStringTag = require('has-tostringtag/shams')();
var hasOwn = require('hasown');
var $TypeError = require('es-errors/type');
var toStringTag = hasToStringTag ? Symbol.toStringTag : null;

/** @type {import('.')} */
module.exports = function setToStringTag(object, value) {
  var overrideIfSet = arguments.length > 2 && !!arguments[2] && arguments[2].force;
  var nonConfigurable = arguments.length > 2 && !!arguments[2] && arguments[2].nonConfigurable;
  if (typeof overrideIfSet !== 'undefined' && typeof overrideIfSet !== 'boolean' || typeof nonConfigurable !== 'undefined' && typeof nonConfigurable !== 'boolean') {
    throw new $TypeError('if provided, the `overrideIfSet` and `nonConfigurable` options must be booleans');
  }
  if (toStringTag && (overrideIfSet || !hasOwn(object, toStringTag))) {
    if ($defineProperty) {
      $defineProperty(object, toStringTag, {
        configurable: !nonConfigurable,
        enumerable: false,
        value: value,
        writable: false
      });
    } else {
      object[toStringTag] = value; // eslint-disable-line no-param-reassign
    }
  }
};