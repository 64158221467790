'use strict';

var hasSymbols = typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol';
var isPrimitive = require('./helpers/isPrimitive');
var isCallable = require('is-callable');
var isDate = require('is-date-object');
var isSymbol = require('is-symbol');

/** @type {(O: { valueOf?: () => unknown, toString?: () => unknown }, hint: 'number' | 'string' | 'default') => null | undefined | string | symbol | number | boolean | bigint} */
var ordinaryToPrimitive = function OrdinaryToPrimitive(O, hint) {
  if (typeof O === 'undefined' || O === null) {
    throw new TypeError('Cannot call method on ' + O);
  }
  if (typeof hint !== 'string' || hint !== 'number' && hint !== 'string') {
    throw new TypeError('hint must be "string" or "number"');
  }
  /** @type {('toString' | 'valueOf')[]} */
  var methodNames = hint === 'string' ? ['toString', 'valueOf'] : ['valueOf', 'toString'];
  var method, result, i;
  for (i = 0; i < methodNames.length; ++i) {
    method = O[methodNames[i]];
    if (isCallable(method)) {
      result = method.call(O);
      if (isPrimitive(result)) {
        return result;
      }
    }
  }
  throw new TypeError('No default value');
};

/** @type {<K extends PropertyKey>(O: Record<K, unknown>, P: K) => Function | undefined} */
var GetMethod = function GetMethod(O, P) {
  var func = O[P];
  if (func !== null && typeof func !== 'undefined') {
    if (!isCallable(func)) {
      throw new TypeError(func + ' returned for property ' + String(P) + ' of object ' + O + ' is not a function');
    }
    return func;
  }
  return void 0;
};

/** @type {import('./es2015')} */
// http://www.ecma-international.org/ecma-262/6.0/#sec-toprimitive
module.exports = function ToPrimitive(input) {
  if (isPrimitive(input)) {
    return input;
  }
  /** @type {'default' | 'string' | 'number'} */
  var hint = 'default';
  if (arguments.length > 1) {
    if (arguments[1] === String) {
      hint = 'string';
    } else if (arguments[1] === Number) {
      hint = 'number';
    }
  }
  var exoticToPrim;
  if (hasSymbols) {
    if (Symbol.toPrimitive) {
      // eslint-disable-next-line no-extra-parens
      exoticToPrim = GetMethod(/** @type {Record<PropertyKey, unknown>} */input, Symbol.toPrimitive);
    } else if (isSymbol(input)) {
      exoticToPrim = Symbol.prototype.valueOf;
    }
  }
  if (typeof exoticToPrim !== 'undefined') {
    var result = exoticToPrim.call(input, hint);
    if (isPrimitive(result)) {
      return result;
    }
    throw new TypeError('unable to convert exotic object to primitive');
  }
  if (hint === 'default' && (isDate(input) || isSymbol(input))) {
    hint = 'string';
  }
  // eslint-disable-next-line no-extra-parens
  return ordinaryToPrimitive(/** @type {object} */input, hint === 'default' ? 'number' : hint);
};