import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DOCUMENT, isPlatformServer } from '@angular/common';

import { RouteHelper, RoutePaths } from '@helper/route-paths';
import { AuthStateService } from '@service/auth-state.service';

import { Observable } from 'rxjs';

export const authGuard = (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | UrlTree | boolean => {
  const router = inject(Router);
  const service = inject(AuthStateService);
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId)) {
    return true;
  }
  if (!service.isLogin()) {
    RouteHelper.setRedirectUrl(state.url);
    return router.navigate([RouteHelper.login()], { queryParams: { redirect_uri: state.url } });
  }
  return true;
};

export const loginGuard = (): Observable<boolean> | Promise<boolean> | UrlTree | boolean => {
  const service = inject(AuthStateService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId)) {
    return false;
  }
  if (service.isLogin()) {
    return router.navigate([RouteHelper.home()], { replaceUrl: true });
  }
  return !service.isLogin();
};

export const previewGuard = (): Observable<boolean> | Promise<boolean> | UrlTree | boolean => {
  const doc = inject(DOCUMENT);
  return doc.location.hostname.includes(RoutePaths.PREVIEW);
};
